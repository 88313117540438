<!-- 关于我们页面 -->
<template>
  <div class="about">
    <!--  头部组件  -->
    <website-header :card-back="cardBack"/>
    <!--  关于数据可信部分 dataReliable  -->
    <data-reliable-index>
      <data-rel-content />
    </data-reliable-index>
  </div>
</template>
<script>
import DataReliableIndex from "@/views/components/about/dataReliableIndex";
import DataRelContent from "@/views/components/about/dataRelContent";
import WebsiteHeader from "@/components/websiteHeader";
export default {
  name: "About",
  components: {WebsiteHeader, DataRelContent, DataReliableIndex},
  data() {
    return {
      cardBack: [
        {carouselOne: require('../assets/banner/关于我们/关于我们.jpg')},
      ],
    }
  }
}
</script>

<style scoped lang="scss">
</style>