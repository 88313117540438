import { render, staticRenderFns } from "./dataRelContent.vue?vue&type=template&id=2155c40f&scoped=true&"
import script from "./dataRelContent.vue?vue&type=script&lang=js&"
export * from "./dataRelContent.vue?vue&type=script&lang=js&"
import style0 from "./dataRelContent.vue?vue&type=style&index=0&id=2155c40f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2155c40f",
  null
  
)

export default component.exports