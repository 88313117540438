<template>
  <div>
    <!--  我们的愿景  -->
    <div class="pt-6 pb-5 sm:py-10 md:py-20">
      <!--  标题  -->
      <div class="title">我们的愿景</div>
      <!--  内容 -->
      <div class="usPlan">
        <!--  左边内容  -->
        <div class="rounded-3xl bg-cover bg-no-repeat px-4">
          <img src="../../../assets/about/aboutRe.png"/>
        </div>
        <!--  右边内容  -->
        <div class="mx-6 mb-4 md:mx-16 2xl:w-3/12">
          <div class="rightTextT">
            <p class="b">
              致力于研究纷繁复杂网络资源的整合方式，实现对网络环境的有序管理。
            </p>
            <p class="b">
              致力于探索复杂区块链网综合治理的应对策略，解决链信息的互认互信及数据的高价值凝炼。
            </p>
            <p class="b">
              以数据可信服务体系为核心，打造跨网络、跨应用、跨业务的服务支点，承载各领域业务整合、数据可信化管理的诉求。
            </p>
            <p class="b">
              为社会提供更安全、可信赖、高价值的互联网应用环境。
            </p>
          </div>
<!--          <div class="mt-6 md:mt-12">
            <div class="flex items-center mb-2 md:mb-5"
                 v-for="(item,index) in dataRight" :key="index">
              <div class="symFront w-2 h-2 rounded mr-3"></div>
              {{item.remText}}
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <!--  选择数据可信的理由  -->
    <div class="selectRe">
      <!--  标题  -->
      <div class="title">
        选择数据可信的理由</div>
      <div class="selectBorder">
        <!-- 循环数据 -->
        <div class="reaDetails" v-for="(item,index) in selectDetails" :key="index">
          <!--w-10 h-10 mr-3-->
          <img class="w-16 h-16 mr-3 md:w-24 md:h-24 md:mr-6" :src="item.img"/>
          <div class="text-left">
            <div class="selectLabel">
              {{item.label}}
            </div><!-- sm:w-56 md:w-3/5 2xl:pr-12 -->
            <div class="selectDet">
              {{item.detailText}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  企业的信赖选择  -->
    <div class="py-8 sm:py-10 md:py-20">
      <!--  标题  -->
      <div class="title">企业的信赖选择</div>
      <div class="flex justify-center">
        <div class="believeBorder">
          <div class="believeFor">
            <div v-for="(item,index) in firmTrustImg" :key="index">
              <!--md:w-48 md:h-24  2xl:w-full 2xl:h-full sm:px-20 -->
              <img class="beForImg"
                    :src="item.firImg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  联系我们  -->
    <div class="contactUs">
      <!--  标题  -->
      <div class="title">联系我们</div>
      <!--  公司地址邮箱  -->
      <div class="flex justify-center items-center">
        <div class="conUs">
          <div class="py-5 px-4 md:py-20 md:px-4">
            <div class="usCompany">
              <div class="usCompany_img"></div>
              <div class="us_title">
                广州新赫信息科技有限公司
              </div>
            </div>
            <div class="text-left mt-2 md:mt-8">
              <div class="usDetailText">
                地址：广州市黄埔区科珠路233号VTRON创新园园区3号楼6层
              </div>
              <div class="usDetailText">TEL： 020-22300395</div>
              <div class="usDetailText">Email：gzxinhe@itxinhe.onaliyun.com</div>
            </div>
          </div>
          <!-- 右侧地图 -->
          <div class="flex items-center">
            <img class="w-full h-full" src="../../../assets/about/usUrl.png"/>
          </div>
        </div>
      </div>
    </div>
    <!--  尾部  -->
    <website-footer/>
  </div>
</template>

<script>
import WebsiteFooter from "@/components/websiteFooter";
export default {
  name: "dataRelContent",
  components: {WebsiteFooter},
  data(){
    return{
      dataRight:[
        {
          remText : '提供高可用数据管理服务'
        },
        {
          remText : '满足多类型可信应用场景'
        },
        {
          remText : '具备区块链可信证实机制'
        }
      ],
      selectDetails : [
        {
          img : require('../../../assets/about/safeshare.png'),
          label : '安全共享',
          detailText : '提供可靠的隐私保护机制，保证敏感源数据不出库及隐私数据安全交换，并引入TEE进一步保证联邦计算的隐私安全。'
        },
        {
          img : require('../../../assets/about/storage.png'),
          label : '可信存储',
          detailText : '打通各种现有的TDS系统，行成统一的组织机构，并兼容各类其它可信系统。通过维护可验证结构实现数据确权，基于多备份容灾和断点续传保证高可用。'
        },
        {
          img : require('../../../assets/about/public.png'),
          label : '透明监管',
          detailText : '链上授权和记录、链下数据交换，基于区块链进行细粒度权限控制，交换记录上链，保证后期不可篡改且可追溯审计。'
        },
        {
          img : require('../../../assets/about/decenter.png'),
          label : '去中心化',
          detailText : '采用P2P网络构建完全去中心化的数据共享网络，原始数据存储在数据拥有方，系统具备更高的可扩展性和抗攻击能力。'
        },
      ],
      firmTrustImg : [
        {
          firImg : require('../../../assets/about/1-1.png')
        },
        {
          firImg : require('../../../assets/about/1-2.png')
        },
        {
          firImg : require('../../../assets/about/1-3.png')
        },
        {
          firImg : require('../../../assets/about/1-4.png')
        },
        {
          firImg : require('../../../assets/about/1-5.png')
        },
        {
          firImg : require('../../../assets/about/1-6.png')
        },
        {
          firImg : require('../../../assets/about/1-7.png')
        },
        {
          firImg : require('../../../assets/about/1-8.png')
        },
        {
          firImg : require('../../../assets/about/1-9.png')
        },
        {
          firImg : require('../../../assets/about/1-10.png')
        },
        {
          firImg : require('../../../assets/about/1-11.png')
        },
        {
          firImg : require('../../../assets/about/1-12.png')
        },
        {
          firImg : require('../../../assets/about/1-13.png')
        },
        {
          firImg : require('../../../assets/about/1-14.png')
        }
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.b{
  letter-spacing: 1px;
  @apply leading-5 2xl:pb-7 2xl:leading-7
}
.title{
  color: #222222;
  @apply
    flex justify-center items-center text-lg font-normal sm:text-xl md:text-2xl
}
.usPlan{
  @apply flex justify-center items-center flex-wrap-reverse pt-5 sm:pt-10 md:pt-20
}
.rightTextT{
  color: #333333;
  @apply text-xs font-normal text-left md:text-base
}
.symFront{
  background: #2555FF;
}
.selectRe{
  background: #FBFBFD;
  @apply w-full pt-8 pb-5 px-5 sm:py-10 md:py-20 md:px-4
}
.selectBorder{
  @apply flex flex-wrap justify-center items-center mt-5 sm:mt-10 md:mt-20 xl:px-24 2xl:px-56
}
.reaDetails{
  background: #F4F6FF;
  @apply
    mb-4 pt-7 pb-4 px-4 rounded-2xl flex items-center
    sm:mx-2 sm:my-2 sm:mb-6 md:mb-12 md:mx-6 md:py-8 md:px-8 md:w-9/12 2xl:w-5/12
}
.selectLabel{
  letter-spacing: 1px;
  @apply text-xs font-black mb-2 sm:mb-1 md:text-lg 2xl:mb-5
}
.selectDet{
  letter-spacing: 1px;
  @apply font-normal text-xs leading-6 md:text-base 2xl:leading-7
}
.firFor{
  width: 1212px;
}
.believeBorder{
  @apply flex w-4/5 ml-4 mt-5 sm:mt-10 md:mt-20 2xl:ml-12 2xl:w-8/12
}
.believeFor{
  @apply flex flex-wrap justify-start items-center 2xl:justify-start
}
.beForImg{
  @apply w-24 h-12 sm:w-24 sm:h-12 md:w-full md:h-full md:mr-1 md:mb-1
}
.contactUs{
  background: #FBFBFD;
  @apply pt-8 pb-5 sm:py-10 md:py-20 w-full
}
.conUs{
  background: #FFFFFF;
  @apply mt-5 sm:mt-10 md:mt-20 flex flex-wrap justify-center
}
.usCompany{
  border-bottom: 1px solid #EEEEEE;
  @apply flex pb-2 md:pb-5
}
.usCompany_img{
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAkCAYAAAAHKVPcAAAEFklEQVRYR+2XXWhcRRTHf+duvmwlRcFabNPEZu82kLYPPiiKSvpSWzSC5t6kNkKJFT9AffALWigUQcSK+tAK/YBSG42au1WwUqhFiAh+PCgYDY29G6lp2ipBpbEtSZvdI7N7N27C3d2sm/RF5+lyZ+ac35w58z8zQpnNdvQ+LDoVLkwq20958mupJqXUCWb8Ta26YEENmwWeANbk2Bi5poam/m65WIrdkiCiHdpopXhUoQu4MY+jdb4nx+ccIuboXQpPIjyUx/gYUJvuU1r9uHwyZxBRRztFeAy4O8ToeYS9KWW/BYeA29MMwvpErxwrC6LxAV0slXSJ8jhwc4ixQRX2VStvD3jyh+m3Xf0RaJ4TiFi7blblLWBhiPPPFPYkPInP7LNd/QFYVTZEg6tLKuHcDAeTCN0k2esflm/yhXjOIGKu3qnwReBoAmVnqoL9Q+/L6bz7u0MtdkhqziDsNr0Ni68Dh8O+J/X5nEcdbRGLjRMVbB3ukT/DIJo6NHY5xW8/e3K+WJJO6UTM1VsVsiEf8ZupN6v8x4BK1GGTJWxRWGv+115H1bf75Irt6gmgKZ0Tyj2JuHwac7Qb4V6FQymldyguX+aDyQdxxod6PEk2btQ6SdIlGYFqyDVUCMJu1z1kTli2DSB8FFF6Bz0xiTzV8kN4ssyMirl6TmFJyCqO+7DBgE7bjiASUVd3CTyVZ/VHL47jnj0il0x/UQjb1XGgeiph4T0jULnhDcsJozeRCPer4ALrZsKIsu1kXF4pCtHSohVnbuAKcFaU3ZEUB098KDOPsRGrgjqxok3tCovWtPRDNA2kHPDjsqUwRDPLG05RVXWJ9ZXK0QFPLperE3a7bkd5KcPA7oQnTxeEWDpKQ1+fTBY6plTzXeJdGYu62i+wOm28QO2w2/VVlBfTNoVdfq88UzgSwenIhVjh6iILNmXvEdYEtT99LH/NtnbYjr6G8Py/goh26C0keUQypfz6LJjfTCRQzEFgZdFIlAzhUQeitquHgQdDtkWXjlJltmz+IhHohO2qzgAYFeWdZIQDQx+IKeHMOidKj0RGrHIgvhI4WAk9A55cyAUrdkSzY0vPCU+WRTdoNQt5HYsjhW5L8wdhTkczOr2IhR/Y+YXwJJlPJ67OdoToRNmKWXJi/g+RifnUpea/lxNNrq5OQn+w8tO+J8tnE4VA0L7PPowVWhKefB42N+royyJsC0r5GwlPnptWRVe26ZqUhTGW2Y6gdswGZJpsK2sTcekLm2e7uhN4Ie1YefNkXJ5Nf8cc3apCJ1ADNAaTjT4MA+a2XezlbmpLHVAVrOoXhd8VrpXMv+yN3dhcDCwKfJgn5AgwJrajPQVe27MJRNljTCS6VXi4bEtlGJBGR++ICKsQc+27+i2ljP8N+YSURI6KM/0AAAAASUVORK5CYII=");
  @apply w-4 h-5 md:w-8 md:h-10 bg-cover
}
.us_title{
  @apply text-sm font-black text-left ml-2 mt-0.5
         md:text-lg md:mt-3 md:ml-4 2xl:text-2xl
}
.usDetailText{
  @apply text-xs mt-3 md:mt-6 2xl:text-lg
}
</style>